import { CssVarsProvider as JoyCssVarsProvider } from "@mui/joy/styles";
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider
} from "@mui/material/styles";
import { unwrapResult } from "@reduxjs/toolkit";
import { ConfirmWrapper } from "components/ConfirmUtils";
import { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { fetchMe } from "redux-store/reducers/auth";
import { setReady } from "redux-store/reducers/common";
import router from "routers";
import theme, { materialTheme } from "theme";
import GlobalCss from "utils/globalCss";
import { NotificationProvider } from "utils/notification";

const App = () => {
  const dispatch = useAppDispatch();
  const ready = useAppSelector((state) => state.common.ready);
  const appSecretCode = useAppSelector((state) => state.common.secretCode);
  const lang = useAppSelector((state) => state.common.lang);
  const auth = useAppSelector((state) => state.auth);
  const triggerReload = useAppSelector((state) => state.common.triggerReload);

  useEffect(() => {
    if (auth.session?.access_token && appSecretCode) {
      dispatch(fetchMe({ isCustomer: auth?.isCustomer }))
        .then(unwrapResult)
        .then(({ response }) => {
          dispatch(setReady(true));
        });
    } else {
      dispatch(setReady(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.session?.access_token]);

  if (!ready) {
    return null;
  }

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider
        key={lang + Date.now() + triggerReload}
        disableNestedContext
        theme={theme}
      >
        <GlobalCss />
        <NotificationProvider>
          <RouterProvider router={router} />
        </NotificationProvider>

        <ConfirmWrapper />
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default App;
