
import { CssBaseline } from "@mui/joy";
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';
import { useEffect } from 'react';

const GlobalCss = () => {
  const { mode, setMode: setMaterialMode } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();

  useEffect(() => {
    if (!mode) {
      setMaterialMode("light");
      setJoyMode("light");
    }
  }, [mode]);

  return <CssBaseline />
}

export default GlobalCss;

