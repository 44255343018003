import Box from '@mui/joy/Box'
import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import AnimationSkeleton from './Loading'

export default function PublicLayout() {
  return (
    <Box>
      <Suspense fallback={<AnimationSkeleton />}>
        <Outlet />
      </Suspense>
    </Box>
  )
}
