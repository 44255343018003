import Card from '@mui/joy/Card';
import Stack from '@mui/joy/Stack';
import { DetailSkeleton } from 'components/SkeletonComponents';

export default function AnimationSkeleton() {
  return (
    <Stack spacing={2} useFlexGap>
      <Card variant="outlined" sx={{ height: "80vh" }}>
        <DetailSkeleton></DetailSkeleton>
      </Card>
    </Stack>
  );
}
