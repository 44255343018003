import PublicLayout from "layout/PublicLayout";
import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import ROUTERS from "routers/constants";

const SignContractPage = React.lazy(() => import("pages/pdf-viewer/SignContract"));
export async function rootLoader(data: any) {
  console.log("rootloader", data);
  return {};
}

const router = createBrowserRouter([
  // public routes
  {
    element: <PublicLayout />,
    errorElement: <h1>Not found</h1>,
    children: [
      {
        path: ROUTERS.SIGN_CONTRACT,
        element: <SignContractPage />,
        id: ROUTERS.SIGN_CONTRACT,
      },
    ],
  },
]);

export default router;
